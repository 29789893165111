// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-facebook-tsx": () => import("/tmp/7b9c9d7c/src/pages/Facebook.tsx" /* webpackChunkName: "component---src-pages-facebook-tsx" */),
  "component---src-pages-github-tsx": () => import("/tmp/7b9c9d7c/src/pages/Github.tsx" /* webpackChunkName: "component---src-pages-github-tsx" */),
  "component---src-pages-hatena-tsx": () => import("/tmp/7b9c9d7c/src/pages/Hatena.tsx" /* webpackChunkName: "component---src-pages-hatena-tsx" */),
  "component---src-pages-icon-tsx": () => import("/tmp/7b9c9d7c/src/pages/Icon.tsx" /* webpackChunkName: "component---src-pages-icon-tsx" */),
  "component---src-pages-index-tsx": () => import("/tmp/7b9c9d7c/src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-name-tsx": () => import("/tmp/7b9c9d7c/src/pages/Name.tsx" /* webpackChunkName: "component---src-pages-name-tsx" */),
  "component---src-pages-twitter-tsx": () => import("/tmp/7b9c9d7c/src/pages/Twitter.tsx" /* webpackChunkName: "component---src-pages-twitter-tsx" */)
}

